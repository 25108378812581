import type { ProfileSummaryType } from '~/endpoints/model/summaries'

export enum ProfileEvent {
  SubscribeClicked = 'profile_subscribe_clicked',
  UnSubscribeClicked = 'profile_unsubscribe_clicked',
  ShareClicked = 'profile_share_clicked',
  TiktokClicked = 'profile_tiktok_clicked',
  InstagramClicked = 'profile_instagram_clicked',
  WebsiteClicked = 'profile_website_clicked',
  YoutubeClicked = 'profile_youtube_clicked',
  BoardClicked = 'profile_board_clicked',
}

export type ProfileEventPayload = {
  profileCreatorToken: string
  profileHasAvatar: boolean
  profileHasCover: boolean
  profileBioLength: number
  boardToken?: string
  boardPrice?: number
}

export const buildProfileEvent = (
  profile: ProfileSummaryType,
  boardToken?: string,
  boardPrice?: number
): ProfileEventPayload => {
  const { uid, photo, cover, bio } = profile
  return {
    profileCreatorToken: uid,
    profileHasAvatar: !!photo,
    profileHasCover: !!cover,
    profileBioLength: bio?.length ?? 0,
    boardPrice: boardPrice,
    boardToken: boardToken,
  }
}
