import { z } from 'zod'

import type { BoardDetails } from '~/endpoints/model/board'

const PaywallEvents = {
  UnlockClicked: 'paywall_unlock_clicked',
  StartUnlockSession: 'paywall_unlock_session_started',
  ShareClicked: 'paywall_share_clicked',
  SaveClicked: 'paywall_save_clicked',
  UnSaveClicked: 'paywall_unsave_clicked',
  ResultViewed: 'paywall_result_viewed',
  ScreenInlineUserSetup: 'paywall_signup_page',
  InlineUserSignUp: 'paywall_user_signup',
  InlineUserLogin: 'paywall_user_login',
} as const

export const PaywallEventsEnum = z.nativeEnum(PaywallEvents)
export type PaywallEventsType = z.infer<typeof PaywallEventsEnum>

export const PaywallEventPayloadSchema = z.object({
  boardToken: z.string(),
  boardPrice: z.number().nullish(),
  boardCountPlaces: z.number(),
  boardCountPhotos: z.number(),
  boardCountLinks: z.number(),
  boardCountVideos: z.number(),
  boardCountWeather: z.number().nullish(),
  boardDescLength: z.number(),
  authorBioLength: z.number(),
  boardSaleType: z.string(),
  isSaved: z.boolean().nullish(),
})

export type PaywallEventPayload = z.infer<typeof PaywallEventPayloadSchema>

export const buildPaywallEvent = (boardDetails: BoardDetails): PaywallEventPayload => {
  const {
    token: boardToken,
    price: boardPrice,
    places: boardCountPlaces,
    links: boardCountLinks,
    videos: boardCountVideos,
    photos: boardCountPhotos,
    description,
    saleType,
    author,
    state: { saved: isSaved },
  } = boardDetails
  return {
    boardToken,
    boardPrice,
    boardCountPhotos,
    boardCountPlaces,
    boardCountLinks,
    boardCountVideos,
    authorBioLength: author.bio?.length ?? 0,
    boardDescLength: description.length,
    boardSaleType: saleType ?? 'content',
    isSaved,
  }
}
