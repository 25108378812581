import { Divider } from '@mantine/core'

import { ProfileSummaryType } from '~/endpoints/model'

import ProfileDescription from './ProfileDescription'
import ProfileSocialMediaSection from './ProfileSocialMediaSection'
import ProfileTagsSection from './ProfileTagsSection'

const ProfileAbout = ({
  profileDetail,
  bioSectionRef,
  showMore,
  setShowMore,
}: {
  profileDetail: ProfileSummaryType
  bioSectionRef: any
  showMore: boolean
  setShowMore: (value: boolean) => void
}) => {
  return (
    <>
      <ProfileDescription
        profile={profileDetail}
        showMoreBio={showMore}
        setShowMoreBio={setShowMore}
        bioRef={bioSectionRef}
      />

      {(!!profileDetail.locations.length ||
        !!profileDetail.travelTypes?.length ||
        !!profileDetail.languages.length) && (
        <>
          <Divider
            my={40}
            color="rgba(0,0,0,0.1)"
          />
          <ProfileTagsSection profileDetails={profileDetail} />
        </>
      )}

      {(profileDetail.youtube || profileDetail.instagram || profileDetail.tiktok) && (
        <>
          <Divider
            color="rgba(0,0,0,0.1)"
            my={40}
          />
          <ProfileSocialMediaSection profileDetails={profileDetail} />
        </>
      )}
    </>
  )
}

export default ProfileAbout
