import { Avatar, Box, Flex } from '@mantine/core'
import { useEffect, useRef, useState } from 'react'
import { Typography } from '~/components/shared/text/Typography'
import { ProfileSummaryType } from '~/endpoints/model'
import { useAuth, useScreenSize } from '~/hooks'

import SellerBadge from '../seller/SellerBadge'
import { IntroVideoModal } from '../seller/services-profile/IntroVideoModal'
import { NetworkImage } from '../shared/image/NetworkImage'
import { getImageUrl } from '../shared/image/helpers'
import { Title, TitleItalic } from '~/components/shared/text/Title'
import { ProfileActionButtons } from './ProfileActionButtons'

interface ProfileIntroProps {
  profile: ProfileSummaryType
  onProfileEdited: () => void
  onClickBio: () => void
  showMoreBio?: boolean
  setShowMoreBio: (value: boolean) => void
}

export const ProfileIntro: React.FC<ProfileIntroProps> = props => {
  const [profile, setProfile] = useState<ProfileSummaryType>(props.profile)
  const [introVideoModalOpened, setIntroVideoModalOpened] = useState(false)
  const { id: authUserId, clientInitialized } = useAuth()


  useEffect(() => {
    setProfile(props.profile)
  }, [props.profile])

  const {
    uid,
    username,
    cover,
    photo,
    sellerIntroVideo,
    name,
    followers,
    proStatus,
    state: { mine: isMine, following: isFollowing },
  } = profile

  const { isMobileScreen } = useScreenSize()

  const containerRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLDivElement>(null)

  const getName = () => {
    const names = name.split(' ')
    return {
      normal: names.slice(0, names.length - 1),
      italic: names[names.length - 1],
    }
  }

  return (
    <Flex
      ref={containerRef}
      direction="column"
      pt={isMobileScreen ? 0 : 40}
    >
      <Box sx={{ position: 'relative', pb: 42 }}>
        <Box
          ref={imageRef}
          sx={cover ? { position: 'relative', paddingTop: '56.25%' } : {}}
          w="100%"
          miw={357}
        >
          {cover ? (
            <NetworkImage
              alt="img"
              style={{ position: 'absolute', top: 0, left: 0 }}
              src={cover}
              quality={100}
              priority
              fill
            />
          ) : (
            <Box
              bg="appPaper.0"
              sx={{ width: isMobileScreen ? '100%' : 357, height: 200 }}
            />
          )}
          {imageRef.current?.offsetWidth &&
            imageRef.current?.offsetHeight &&
            containerRef.current?.clientWidth &&
            clientInitialized && (
              <Avatar
                src={getImageUrl({ src: photo })}
                w={108}
                h={108}
                radius="50%"
                sx={{
                  border: '1px solid black',
                  position: 'absolute',
                  top: (imageRef.current?.offsetHeight || 0) - 72,
                  left: (imageRef.current?.offsetWidth || 0) / 2 - 54,
                }}
              />
            )}
        </Box>

        <Box px={isMobileScreen ? 16 : undefined}>
          <Flex
            direction="column"
            mt={isMobileScreen ? 68 : 76}
            gap={8}
          >
            <SellerBadge type={proStatus} />
            <Title variant="h3" order={1}>{getName().normal} <TitleItalic>{getName().italic}</TitleItalic></Title>
            <Typography
              variant={isMobileScreen ? 'body1' : 'body2'}
              color="appPlaceholder.0"
            >
              {`${followers || 0} Subscribers`}
            </Typography>
          </Flex>
          <ProfileActionButtons profile={profile} setProfile={setProfile} />
        </Box>
      </Box>

      {sellerIntroVideo && (
        <IntroVideoModal
          opened={introVideoModalOpened}
          setOpened={setIntroVideoModalOpened}
          videoUrl={sellerIntroVideo}
        />
      )}
    </Flex>
  )
}
