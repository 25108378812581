import { Sx, createStyles } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

import { InlineAuthView } from '~/components/auth/InlineAuthView'
import { ThatchButton } from '~/components/shared/ThatchButton'
import { notify } from '~/components/shared/notify'
import { useCurrentAuthState } from '~/context'
import { ProfileSummaryType } from '~/endpoints/model'
import { followUnfollow } from '~/endpoints/user'
import {
  LOCAL_STORAGE_MOST_RECENT_UPDATE,
  REACT_QUERY_SUBSCRIPTION_UPDATES_KEY,
} from '~/utils/constants'

export interface SubscriptionButtonProps {
  lightTheme?: boolean
  isFollowing: boolean
  userName: string
  sx?: Sx
  onSuccess: (summary: ProfileSummaryType) => void
  analyticsCallback?: (isFollowing: boolean) => void
}

const useStyle = createStyles((theme, { lightTheme }: { lightTheme?: boolean }) => ({
  subscribe: {
    color: lightTheme ? theme.colors.appBlack : theme.colors.appWhite,
  },
  root: {
    border: `1px solid ${theme.colors.appBlack}`,
    padding: '0 32px',
  },
}))

export const SubscriptionButton: React.FC<SubscriptionButtonProps> = ({
  userName,
  isFollowing,
  lightTheme,
  sx,
  onSuccess,
  analyticsCallback,
}) => {
  const { classes } = useStyle({ lightTheme })
  const { email } = useCurrentAuthState()
  const queryClient = useQueryClient()
  const [showInlineAuth, setShowInlineAuth] = useState<boolean>(false)

  const [, setRecentUpdate] = useLocalStorage({
    key: LOCAL_STORAGE_MOST_RECENT_UPDATE,
  })

  const { isPending, mutateAsync } = useMutation({
    mutationFn: ({ username, following }: { username: string; following: boolean }) =>
      followUnfollow(username, following),
  })

  const performAction = async () => {
    try {
      const updatedProfile = await mutateAsync({ username: userName, following: isFollowing })
      // reset last cached timestamp
      setRecentUpdate('')
      // invalidate subscription updates query to get new updates
      await queryClient.invalidateQueries({
        queryKey: [REACT_QUERY_SUBSCRIPTION_UPDATES_KEY],
      })
      onSuccess(updatedProfile)
    } catch (error) {
      notify(true, error.message)
    }
  }

  const onUserSetupSuccess = async (_currentUserUid: string) => {
    await performAction()
    setShowInlineAuth(false)
    notify(false, 'You are subscribed', 'Subscribed')
  }

  const handleClick = async () => {
    analyticsCallback?.(isFollowing)
    if (!email) {
      setShowInlineAuth(true)
    } else {
      analyticsCallback?.(isFollowing)
      await performAction()
    }
  }
  return (
    <>
      <ThatchButton
        sx={sx}
        color={lightTheme ? 'appWhite.0' : undefined}
        size="mobileLarge"
        onClick={handleClick}
        label={isFollowing ? 'Subscribed' : 'Subscribe'}
        classNames={{
          root: classes.root,
          label: classes.subscribe,
        }}
        loading={isPending}
      />
      {showInlineAuth && (
        <InlineAuthView
          launchSource="subscribe"
          onClose={() => setShowInlineAuth(false)}
          isOpened={showInlineAuth}
          initLandingView="signup"
          onEmailAuthSuccess={onUserSetupSuccess}
          profileName={userName}
        />
      )}
    </>
  )
}
