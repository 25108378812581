import { Flex } from '@mantine/core'

import { Language, LocationType, ProfileSummaryType } from '~/endpoints/model'
import { useScreenSize } from '~/hooks'

import { TagSection } from '../seller/service-setup/TagsSection'

const ProfileTagsSection = ({ profileDetails }: { profileDetails: ProfileSummaryType }) => {
  const { isMobileScreen } = useScreenSize()

  return (
    <Flex
      direction="column"
      gap={48}
      maw={!isMobileScreen ? 357 : '100%'}
      px={isMobileScreen ? 16 : undefined}
    >
      {!!profileDetails.locations.length && (
        <TagSection
          title="DESTINATIONS"
          tags={profileDetails?.locations?.map((item: LocationType) => item?.name ?? '') ?? []}
        />
      )}
      {!!profileDetails.travelTypes?.length && (
        <TagSection
          title="FOCUSES & SPECIALTIES"
          tags={profileDetails.travelTypes ?? []}
        />
      )}
      {!!profileDetails.languages.length && (
        <TagSection
          title="LANGUAGES"
          tags={profileDetails.languages.map((language: Language) => language.name) ?? []}
        />
      )}
    </Flex>
  )
}

export default ProfileTagsSection
