import { parseFactory } from '~/endpoints/model'
import { StripeSellerSchema } from '~/endpoints/model/seller'
import { getAuthClient as apiClient } from '~/utils/apiClient'
import { SSRAuthToken } from '~/utils/ssr'

const sellerSetupParser = parseFactory(StripeSellerSchema, 'SellerSetup')
const root = '/payments/seller'

export const getSellerStatus = async (auth?: SSRAuthToken) => {
  const httpClient = apiClient(auth)
  const res = await httpClient.get(`${root}/status`)
  return sellerSetupParser(res.data)
}

export const acceptSellerTos = async () => {
  const httpClient = apiClient()
  const res = await httpClient.post(`${root}/tos`)
  return sellerSetupParser(res.data)
}

export const submitOnboarding = async (answers: Record<string, unknown>) => {
  const httpClient = apiClient()
  const res = await httpClient.post(`${root}/onboarding`, answers)
  return sellerSetupParser(res.data)
}

export const deleteSellerDetails = async (uid: string) => {
  const httpClient = apiClient()
  await httpClient.delete(`${root}/${uid}`)
}
