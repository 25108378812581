import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { ThatchProStatus } from '~/endpoints/model'
import { fetchProfileSummary, updateThatchProStatus } from '~/endpoints/user'
import { useAuth } from '~/hooks/useAuth'

export function useProfile(username: string) {
  const auth = useAuth()
  return useQuery({
    queryKey: ['profile', username],
    queryFn: () => fetchProfileSummary(`@${username}`, auth?.getIdToken),
    refetchOnWindowFocus: false,
  })
}

export function useMutateThatchProStatus(uid: string) {
  const queryClient = useQueryClient()
  const auth = useAuth()

  return useMutation({
    mutationFn: (status: ThatchProStatus) => {
      return updateThatchProStatus(uid, status, auth?.getIdToken)
    },
    onSuccess: profile => {
      queryClient.setQueryData(['profile', profile.username], profile)
    },
  })
}
