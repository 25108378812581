import { Group } from '@mantine/core'

import { Typography } from '../shared/text/Typography'
import { StarFilled } from './ReviewIcons'

export const ServicesOverviewRatings = ({
  rating,
  reviews,
}: {
  rating?: number | null
  reviews?: number | null
}) => (
  <>
    {rating && reviews && (
      <Group spacing={8}>
        <StarFilled
          size="24"
          fill="#000"
        />
        <Typography variant="body1">
          {rating.toFixed(1) ?? 0}{' '}
          <Typography
            color="appPlaceholder.0"
            span
          >
            ({reviews} Ratings & Reviews)
          </Typography>
        </Typography>
      </Group>
    )}
  </>
)
