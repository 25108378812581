import { z } from 'zod'

export const StripeSellerSchema = z.object({
  showTos: z.boolean().default(true),
  showOnboarding: z.boolean().default(true),
  setupUrl: z.string().nullable(),
  complete: z.boolean().default(false),
  sellerId: z.string().nullable(),
})

export type SellerSetup = z.infer<typeof StripeSellerSchema>
