import { createStyles, keyframes } from '@mantine/core'
import Image, { ImageProps, StaticImageData } from 'next/image'
import React, { useEffect, useState } from 'react'

import { LocalImage } from '~/components/shared/image/LocalImage'
import {
  cloudflareLoader,
  isAbsoluteImageUrl,
  isValidImageUrl,
} from '~/components/shared/image/helpers'

import empty from 'public/images/cover/empty.jpg'

// Alternate syle for loading image using blur
/**
 * Usage:
 * <Image
      {...props}
      alt={props.alt}
      className={cx(
        props.className,
        classes.common,
        isLoading ? classes.loading : class.loadingComplete)}
      onLoadingComplete={() => setLoading(false)}
    />
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const alternateStyle = createStyles(_ => ({
  common: {
    transitionDuration: '.7s',
    transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
  },
  loadingComplete: {
    filter: 'grayscale(0) blur(0px)',
    transform: 'scale(1)',
  },
  loading: {
    filter: 'grayscale(1) blur(10px)',
    transform: 'scale(1.1)',
  },
}))

interface NetworkImageProps extends Omit<ImageProps, 'alt' | 'src'> {
  alt?: string
  src?: string | null
}

export const shimmer = keyframes({
  '0%': {
    backgroundPosition: '-1000px 0',
  },
  '100%': {
    opacity: '1000px 0%',
  },
})

const useStyle = createStyles(_theme => ({
  shimmerAnimation: {
    backgroundImage:
      'linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)',
    backgroundSize: '1000px 100%',
    animation: `${shimmer} 1s infinite`,
  },
}))

export const NetworkImageWithPlaceHolder: React.FC<ImageProps> = props => {
  const src = props.src as string
  const [isLoading, setLoading] = useState(true)
  const { classes, cx } = useStyle()
  const isAbsoluteUrl = isAbsoluteImageUrl(src)
  const [imgSrc, setImgSrc] = useState<StaticImageData | string>(src)
  const [isError, setError] = useState<boolean>(false)

  const handleOnError = () => {
    setError(true)
    setImgSrc(empty)
  }

  useEffect(() => {
    setImgSrc(src)
  }, [src])

  return (
    <Image
      {...props}
      src={imgSrc}
      loader={isError ? undefined : cloudflareLoader}
      unoptimized={isAbsoluteUrl}
      alt={props.alt}
      className={cx(props.className, isLoading ? classes.shimmerAnimation : '')}
      onLoadingComplete={() => setLoading(false)}
      onError={handleOnError}
    />
  )
}
/**
 * NextJS Image component with loading shimmer support
 * More Info: https://github.com/vercel/next.js/discussions/26168
 * @param props
 * @returns
 */
export const NetworkImage: React.FC<NetworkImageProps> = props => {
  const isValidUrl = isValidImageUrl(props.src)

  if (!isValidUrl) {
    console.warn('Rendering image with invalid URL: %s', props.src)
    return (
      <LocalImage
        {...props}
        fill={undefined}
        alt={props.alt ?? ''}
        src={empty}
      ></LocalImage>
    )
  }

  return (
    <NetworkImageWithPlaceHolder
      {...props}
      alt={props.alt ?? ''}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      src={props.src!}
    />
  )
}
