import { NextSeo, SocialProfileJsonLd } from 'next-seo'
import React from 'react'

import { getImageUrl } from '~/components/shared/image/helpers'
import { ProfileDetail } from '~/endpoints/model/user'
import { HOST } from '~/seo/constants'
import { isNonNull } from '~/utils/helper'

import homeImg from 'public/images/og/home.jpg'

type ProfileSeoWrapperProps = {
  children: React.ReactNode
  profile: ProfileDetail
}

export const ProfileSeoWrapper: React.FC<ProfileSeoWrapperProps> = ({ children, profile }) => {
  const { bio, cover, username, name } = profile
  return (
    <>
      <NextSeo
        title={`Explore ${name}'s Travel Shop`}
        description={bio ?? ''}
        openGraph={{
          type: 'website',
          title: `Explore ${name}'s Travel Shop`,
          description: `${bio}`,
          url: `${HOST}/@${username}`,
          profile: {
            username: profile.username,
          },
          images: [
            {
              url: `${getImageUrl({ src: cover, quality: 80 }) ?? homeImg.src}`,
            },
          ],
        }}
        twitter={{
          cardType: 'summary_large_image',
        }}
      />
      <SocialProfileJsonLd
        type="Person"
        name={profile.name}
        url={profile.website ?? `${HOST}/@${username}`}
        sameAs={[profile.tiktok, profile.instagram, profile.youtube].filter(isNonNull)}
      />
      {children}
    </>
  )
}
