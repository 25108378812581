import { Flex } from '@mantine/core'
import Link from 'next/link'

import { ProfileSummaryType } from '~/endpoints/model'
import { useScreenSize } from '~/hooks'

import { SvgIcon, SvgIconType } from '../shared/image/SvgIcon'
import { Typography } from '../shared/text/Typography'

const SocialMediaBtn = ({ url, icon }: { url: string; icon: SvgIconType }) => (
  <Link
    target="_blank"
    href={url ?? ''}
  >
    <Flex
      align="center"
      justify="center"
      w={36}
      h={36}
      sx={{ border: '1px solid rgba(0, 0, 0, 0.30)', borderRadius: 32 }}
    >
      <SvgIcon type={icon} />
    </Flex>
  </Link>
)

const ProfileSocialMediaSection = ({ profileDetails }: { profileDetails: ProfileSummaryType }) => {
  const { isMobileScreen } = useScreenSize()
  const { youtube, tiktok, instagram } = profileDetails

  return (
    <Flex
      direction="column"
      gap={16}
      mx={isMobileScreen ? 16 : 0}
    >
      <Typography variant="eyebrow">GET INSPIRED</Typography>

      <Flex gap={16}>
        {youtube && (
          <SocialMediaBtn
            icon={SvgIconType.YOUTUBE_ICON}
            url={youtube}
          />
        )}
        {tiktok && (
          <SocialMediaBtn
            icon={SvgIconType.TIKTOK_ICON}
            url={tiktok}
          />
        )}
        {instagram && (
          <SocialMediaBtn
            icon={SvgIconType.INSTAGRAM_ICON}
            url={instagram}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default ProfileSocialMediaSection
