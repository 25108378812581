import { Box, Divider, Flex, Space } from '@mantine/core'

import { ProfileSummaryType, SellerService, ServiceType } from '~/endpoints/model'
import { useScreenSize } from '~/hooks'

import { ServicesOverviewRatings } from '../reviews/ServicesOverviewReviewSummary'
import { ThatchLink } from '../shared/ThatchLink'
import { SvgIcon, SvgIconType } from '../shared/image/SvgIcon'
import { Typography } from '../shared/text/Typography'
import { Title, TitleItalic } from '../shared/text/Title'

const ProfileServices = ({ profileDetails }: { profileDetails?: ProfileSummaryType }) => {
  const { isMobileScreen } = useScreenSize()

  if (!profileDetails?.consultation && !profileDetails?.itinerary && !profileDetails?.recs) {
    return null
  }

  const getData = (service: SellerService) => {
    const getStartingAtPrice = (prices: (number | null | undefined)[]) => {
      const validPrices = prices.filter((it: any): it is number => !isNaN(it))
      return validPrices.length ? Math.min(...validPrices) : 0
    }

    switch (service.type) {
      case ServiceType.consultation:
        return {
          title: ['Book a', '1:1 Video Consultation'],
          icon: <SvgIcon type={SvgIconType.CONSULTATION_ICON} />,
          startingAt: getStartingAtPrice([
            profileDetails?.consultation?.base?.price,
            profileDetails?.consultation?.standard?.price,
            profileDetails?.consultation?.premium?.price,
          ]),
        }
      case ServiceType.itinerary:
        return {
          title: ['Order a', 'Custom Itinerary'],
          icon: <SvgIcon type={SvgIconType.ITINERARY_ICON} />,
          startingAt: getStartingAtPrice([
            profileDetails?.itinerary?.base?.price,
            profileDetails?.itinerary?.standard?.price,
            profileDetails?.itinerary?.premium?.price,
          ]),
        }
      case ServiceType.recs:
        return {
          title: ['Order a', 'Personalized Guide'],
          icon: <SvgIcon type={SvgIconType.RECS_SERVICE_ICON} />,
          startingAt: getStartingAtPrice([
            profileDetails?.recs?.base?.price,
            profileDetails?.recs?.standard?.price,
            profileDetails?.recs?.premium?.price,
          ]),
        }
    }
  }

  const ServiceCard = ({
    redirectUrl,
    service,
    isMobileScreen,
  }: {
    redirectUrl: string
    service: SellerService
    isMobileScreen: boolean
  }) => {
    const { icon, startingAt, title } = getData(service)

    return (
      <Flex
        direction="column"
        bg="appPaper.0"
        p="24px 16px"
        maw={isMobileScreen ? undefined : 350}
        sx={{
          borderRadius: 24,
          flex: 1,
          maxWidth: '100%',
          cursor: 'pointer',
          ':hover': { border: '1px solid rgba(0,0,0,0.1)' },
        }}
      >
        <ThatchLink
          to={redirectUrl}
          underline={false}
          isOpenInNewWindow
        >
          {icon}
          <Space h={12} />

          <Box>
            <Typography variant="body3">{title[0]}</Typography>
            <Typography variant="body3">{title[1]}</Typography>
          </Box>

          <Space h={!isMobileScreen ? 48 : 32} />

          <Typography
            variant="button_small"
            sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
          >
            Learn More{' '}
            <SvgIcon
              fill="appBlack.0"
              type={SvgIconType.ARROW_RIGHT}
            />
          </Typography>
        </ThatchLink>
      </Flex>
    )
  }

  return (
    <>
      <Flex
        w="100%"
        sx={{ flex: 1 }}
        gap={32}
        pt={!isMobileScreen ? 0 : 24}
        direction="column"
      >
        {!isMobileScreen && (
          <Title variant="titleSerif" order={2}>Planning <TitleItalic>Services</TitleItalic></Title>
        )}
        <ServicesOverviewRatings
          rating={profileDetails.rating}
          reviews={profileDetails.reviews}
        />
        <Flex
          gap={16}
          direction={isMobileScreen ? 'column' : 'row'}
        >
          {profileDetails?.consultation && !profileDetails.consultation.disabled && (
            <ServiceCard
              redirectUrl={`/seller/services/consultation/@${profileDetails.username}`}
              service={profileDetails.consultation}
              isMobileScreen={!isMobileScreen}
            />
          )}
          {profileDetails?.itinerary && !profileDetails.itinerary.disabled && (
            <ServiceCard
              redirectUrl={`/seller/services/itinerary/@${profileDetails.username}`}
              service={profileDetails.itinerary}
              isMobileScreen={!isMobileScreen}
            />
          )}
          {profileDetails?.recs && !profileDetails.recs.disabled && (
            <ServiceCard
              redirectUrl={`/seller/services/recs/@${profileDetails.username}`}
              service={profileDetails.recs}
              isMobileScreen={!isMobileScreen}
            />
          )}
        </Flex>
      </Flex>
      {!isMobileScreen && (
        <Divider
          my={40}
          color="rgba(0,0,0,0.1)"
        />
      )}
    </>
  )
}

export default ProfileServices
