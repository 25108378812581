import { Box, Center, Flex, Group, MediaQuery, Space, createStyles } from '@mantine/core'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { ThatchButton } from '~/components/shared/ThatchButton'
import { LocalImage } from '~/components/shared/image/LocalImage'
import { Typography } from '~/components/shared/text/Typography'
import { useCurrentAuthState } from '~/context'
import { fetchAllPublishedBoardByUser } from '~/endpoints/board'
import { ProfileDetail } from '~/endpoints/model'

import { GuidesContent } from '../explore/GuidesContent'

import created from 'public/images/illustrations/create.png'

interface ProfileGuidesProps {
  profile: ProfileDetail
}

const useStyle = createStyles(_theme => ({
  image: {
    objectFit: 'cover',
  },
  mobileContainer: {
    padding: '48px 20px 82px 20px',
  },
}))

const ProfileGuideEmptyState = ({
  isLoggedIn,
  isOwner,
}: {
  isLoggedIn: boolean
  isOwner: boolean
}) => {
  const { classes } = useStyle()
  const router = useRouter()
  return (
    <>
      <MediaQuery
        smallerThan="sm"
        styles={{ display: 'none' }}
      >
        <Center>
          <Flex
            direction="row"
            align="center"
            justify="space-between"
          >
            <Box>
              <Typography
                variant="h3"
                mb={16}
              >
                Nothing published{' '}
                <Typography
                  variant="h3Italic"
                  span
                >
                  yet...
                </Typography>
              </Typography>
              <Typography
                variant="body1"
                mb={40}
              >
                Publish guides you want to share with the world.
              </Typography>
              <Group spacing={12}>
                {isLoggedIn && isOwner && (
                  <ThatchButton
                    size="mobileLarge"
                    label="Publish a Guide"
                    onClick={() => router.push('/guides')}
                  />
                )}
                <ThatchButton
                  variant="outline"
                  size="mobileLarge"
                  bg="appWhite.0"
                  label="Learn about Publishing"
                  sx={theme => ({
                    border: `1px solid ${theme.colors.appBlack}`,
                  })}
                  onClick={() =>
                    window.open(
                      'https://thatch.notion.site/Publish-Content-to-your-Page-Shop-d274acb5e7074c76868d7f4e40bba279',
                      '_blank'
                    )
                  }
                />
              </Group>
            </Box>
            <LocalImage
              className={classes.image}
              src={created}
              width={300}
              height={300}
            />
          </Flex>
        </Center>
      </MediaQuery>
      <MediaQuery
        largerThan="sm"
        styles={{ display: 'none' }}
      >
        <Flex
          className={classes.mobileContainer}
          direction="column"
          align="center"
        >
          <Typography
            variant="h3"
            mb={16}
          >
            Nothing published{' '}
            <Typography
              variant="h3Italic"
              span
            >
              yet...
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            mb={40}
          >
            Publish guides you want to share with the world.
          </Typography>
          <LocalImage
            className={classes.image}
            src={created}
            width={280}
            height={280}
          />
          <Space h={32} />
          {isLoggedIn && isOwner && (
            <ThatchButton
              size="mobileLarge"
              label="Publish a Guide"
              onClick={() => router.push('/guides')}
              mb={18}
              fullWidth
            />
          )}
          <ThatchButton
            variant="outline"
            size="mobileLarge"
            bg="appWhite.0"
            sx={theme => ({
              border: `1px solid ${theme.colors.appBlack}`,
            })}
            fullWidth
            label="Learn about Publishing"
            onClick={() =>
              window.open(
                'https://thatch.notion.site/Publish-Content-to-your-Page-Shop-d274acb5e7074c76868d7f4e40bba279',
                '_blank'
              )
            }
          />
        </Flex>
      </MediaQuery>
    </>
  )
}

export const ProfileGuides: React.FC<ProfileGuidesProps> = ({ profile }) => {
  const { email } = useCurrentAuthState()
  const username = profile.username
  const profileHandle = username.length > 0 && !username.startsWith('@') ? `@${username}` : username
  const [fetchEnabled, setFetchedEnabled] = useState<boolean>(false)

  // trigger fetch on prop updates
  useEffect(() => {
    setFetchedEnabled(Boolean(email) && !profile.state.mine)
  }, [email, profile.state.mine])

  const { data: boards } = useQuery({
    queryKey: [`publishedContent-${username}`],
    queryFn: () => fetchAllPublishedBoardByUser(profileHandle).then(it => it.content),
    initialData: profile.publishedBoards.content,
    gcTime: 0,
    staleTime: Infinity,
    enabled: fetchEnabled,
  })

  return boards.length > 0 ? (
    <GuidesContent
      wrapperStyles={{ padding: 0, margin: 0 }}
      data={boards}
      emptyState={null}
      isLoading={false}
      onFetchData={() => {}}
      hasMore={false}
    />
  ) : (
    <ProfileGuideEmptyState
      isLoggedIn={Boolean(email)}
      isOwner={profile.state.mine}
    />
  )
}
