import { Box, Flex, Group } from '@mantine/core'
import React, { CSSProperties, ReactNode, useRef } from 'react'

import { MantineNextLink } from '~/components/shared/MantineNextLink'
import { Typography } from '~/components/shared/text/Typography'
import { typography } from '~/theme'

interface SectionTitleAndSubtitleProps {
  title: { normal: string; italic: string; gap?: number }
  subtitle: {
    text: string
    linkText?: string
    redirectUrl?: string
  }[]
  style?: CSSProperties
  error?: string
  children: ReactNode
}

export const SectionLayout: React.FC<SectionTitleAndSubtitleProps> = ({
  title,
  subtitle,
  style,
  error,
  children,
}) => {
  const titleRef = useRef<HTMLDivElement>(null)

  return (
    <Flex
      style={style}
      direction="column"
      gap={48}
    >
      <Flex
        direction="column"
        sx={{ maxWidth: 640 }}
        gap={24}
      >
        <Box ref={titleRef}>
          <Typography
            variant="h3"
            sx={{ display: 'flex', flexDirection: 'row', gap: title.gap || 8 }}
          >
            {title.normal} <Typography variant="h3Italic">{title.italic}</Typography>
          </Typography>
        </Box>

        <Flex
          direction="column"
          gap={4}
        >
          {subtitle.map((textObj, idx) => {
            if (!textObj.redirectUrl) {
              return (
                <Typography
                  key={idx}
                  variant="body3"
                >
                  {textObj.text}
                </Typography>
              )
            }
            return (
              <Group
                key={idx}
                display="inline-flex"
                sx={{ gap: 4 }}
              >
                {textObj.text.split(' ').map((word: string, i: number) => (
                  <Typography
                    key={i}
                    variant="body3"
                  >
                    {word}
                  </Typography>
                ))}

                <MantineNextLink
                  style={{
                    textDecoration: 'underline',
                    justifySelf: 'end',
                    ...(typography.body3 as CSSProperties),
                  }}
                  href={textObj.redirectUrl}
                  target="_blank"
                >
                  {textObj.linkText}
                </MantineNextLink>
              </Group>
            )
          })}
        </Flex>

        {error && (
          <Box>
            <Typography
              variant="caption"
              color="appPoppy.0"
            >
              {error}
            </Typography>
          </Box>
        )}
      </Flex>
      {children}
    </Flex>
  )
}
