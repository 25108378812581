import { Box, Divider, Flex } from '@mantine/core'

import { ProfileSummaryType } from '~/endpoints/model'
import { useScreenSize } from '~/hooks'

import { SvgIcon, SvgIconType } from '../shared/image/SvgIcon'
import { ShowLessText } from '../shared/text/ShowLessText'
import { ShowMoreText } from '../shared/text/ShowMoreText'
import { TextReadMoreOrLess } from '../shared/text/TextReadMoreOrLess'
import { Typography } from '../shared/text/Typography'

interface ProfileDescriptionProps {
  profile: ProfileSummaryType
  showMoreBio?: boolean
  setShowMoreBio: (value: boolean) => void
  bioRef?: any
}

const ProfileDescription = ({
  profile,
  setShowMoreBio,
  bioRef,
  showMoreBio,
}: ProfileDescriptionProps) => {
  const { isMobileScreen } = useScreenSize()
  const { bio, home, hireable, stripeSellerId } = profile

  return (
    <Box px={isMobileScreen ? 16 : 0}>
      <Box
        ref={bioRef}
        mb={40}
      >
        {bioRef?.current && profile.bio && (
          <TextReadMoreOrLess
            id="text-details"
            variant="body1"
            text={bio}
            containerSx={{
              marginTop: 8,
              width: isMobileScreen ? '100%' : 357,
              paddingRight: isMobileScreen ? 16 : undefined,
            }}
            readMore={<ShowMoreText />}
            readLess={<ShowLessText />}
            parentShowMore={showMoreBio}
            parentSetShowMore={setShowMoreBio}
            linesLimit={5}
            enabled
          />
        )}
      </Box>

      {(stripeSellerId || home || hireable) && (
        <>
          <Divider
            my={40}
            mx={-16}
            color="rgba(0,0,0,0.1)"
          />

          <Flex
            direction="column"
            gap={16}
          >
            {home && (
              <Flex gap={8}>
                <SvgIcon type={SvgIconType.PROFILE_HOME_ICON} />
                <Typography variant="body3">{home.name}</Typography>
              </Flex>
            )}
            {stripeSellerId && (
              <Flex gap={8}>
                <SvgIcon type={SvgIconType.SHIELD_ICON} />
                <Typography variant="body3">Identity Verified</Typography>
              </Flex>
            )}
            {hireable && (
              <Flex gap={8}>
                <SvgIcon type={SvgIconType.AVAILABLE_FOR_HIRE_ICON} />
                <Typography variant="body3">Available for Hire</Typography>
              </Flex>
            )}
          </Flex>
        </>
      )}
    </Box>
  )
}

export default ProfileDescription
